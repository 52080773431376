import React from "react";
import { Link } from "react-router-dom";
import { Banner } from "../Assets";
import styled from "styled-components";

const Head = styled.section`
  @media screen and (max-width: 420px) {
    h1,
    p,
    a {
      margin-left: -15px;
      margin-right: 0px;
    }
  }
`;

const Header = () => {
  return (
    <Head className="container head" data-aos="fade-out">
      <div className="row clearfix">
        <div className="col-lg-6 ht">
          <div className="mt-4 ml-3 p-y-auto">
            <h1>
              Bisa simpel kelola tim, evaluasi hasil kerjaan mudah, punya banyak
              waktu untuk kembangin bisnis?
            </h1>
            <p className={`pb-4`}>
              Sekarang ada apps untuk pembagian kerjaan ke tim, monitoring
              hasil, dan evaluasi kinerja, semuanya dalam satu halaman apps,
              simpel, mudah, dan cepat. Tim kompak, kerjaan memuaskan, bisnis
              lancar.
            </p>
            <div className={`mt-3`}>
              <a
                href="https://my.masteryapps.id"
                className="registerButton text-dark mt-4 mr-4 px-4 py-1"
              >
                Coba Mastery
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img src={Banner.url} alt={Banner.alt} />
        </div>
      </div>
    </Head>
  );
};

export default Header;
