import React from "react";
import LeftBar from "../../parts/LeftBar";
import HeaderDashboard from "../../parts/HeaderDashboard";
import { Konten } from "../../parts/HeaderDashboard/Style";
import FormTambahReward from "../../parts/FormTambahReward";
import FormEditReward from "../../parts/FormEditReward/FormEditReward";
import FormDelete from "../../parts/FormDelete";

const Reward = () => {
  const [reward, setReward] = React.useState([]);
  const rewardAdd = () => {
    setReward([
      {
        id: 1,
        rewardName: "Tiket Nonton",
        desc: "Avengers",
        points: 600,
        stocks: 12,
      },
      {
        id: 2,
        rewardName: "Tiket Dufan",
        desc: "Berlaku 5 kali",
        points: 450,
        stocks: 20,
      },
      {
        id: 3,
        rewardName: "Macbook",
        desc: "Macbook Pro 2020",
        points: 200,
        stocks: 5,
      },
      {
        id: 4,
        rewardName: "Smartphone",
        desc: "Iphone 12 Max pro",
        points: 450,
        stocks: 10,
      },
      {
        id: 5,
        rewardName: "Camera DSLR",
        desc: "Sony a7IIS",
        points: 10000,
        stocks: 5,
      },
      {
        id: 6,
        rewardName: "Tiket Nonton",
        desc: "Avengers",
        points: 345,
        stocks: 50,
      },
      {
        id: 7,
        rewardName: "Tiket Dufan",
        desc: "Berlaku 5 kali",
        points: 600,
        stocks: 5,
      },
      {
        id: 8,
        rewardName: "Tiket Dufan",
        desc: "emailUser@gmail.com",
        points: 200,
        stocks: 2,
      },
      {
        id: 9,
        rewardName: "Macbook",
        desc: "emailUser@gmail.com",
        points: 450,
        stocks: 50,
      },
      {
        id: 10,
        rewardName: "Smartphone",
        desc: "emailUser@gmail.com",
        points: 2000,
        stocks: 60,
      },
      {
        id: 11,
        rewardName: "Camera DSLR",
        desc: "emailUser@gmail.com",
        points: 1750,
        stocks: 29,
      },
      {
        id: 12,
        rewardName: "Tiket Nonton",
        desc: "emailUser@gmail.com",
        points: 600,
        stocks: 2,
      },
    ]);
  };
  React.useEffect(() => {
    rewardAdd();
  }, []);

  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar menuActive="reward" />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              {/* konten header */}
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    <b id="title">Reward</b>
                    <br />
                    Testmastery01
                  </p>
                </div>
                <div className="col-lg-5">
                  <button
                    id="button"
                    className="btn float-right"
                    type="button"
                    data-toggle="modal"
                    data-target="#tambahReward"
                  >
                    Buat Reward
                  </button>
                  {/* konten header akhir*/}
                </div>
                <div className="col-lg-12">
                  <div className="card">
                    <div className="header"></div>
                    <div className="body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr style={{ backgroundColor: "#FFE8B1" }}>
                              <th>Nama</th>
                              <th>Description</th>
                              <th>Points</th>
                              <th>Stocks</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reward.map((re) => (
                              <tr
                                style={
                                  re.id % 2 === 0
                                    ? { backgroundColor: "#F9F2EC" }
                                    : { backgroundColor: "white" }
                                }
                                key={re.id}
                              >
                                <th scope="row">{re.rewardName}</th>
                                <td>{re.desc}</td>
                                <td>{re.points}</td>
                                <td>{re.stocks}</td>
                                <td>
                                  <div className="d-flex justfy-content-center">
                                    <span
                                      className="ti-pencil pr-2"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#editReward"
                                    ></span>
                                    <span
                                      className="ti-trash pl-2"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#delete"
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr className="footable-paging">
                              <td colSpan="5">
                                <ul className="pagination float-right">
                                  <span className="label label-default4">
                                    Page 1 of 2 &nbsp; {"<"} &nbsp; {">"}
                                  </span>
                                </ul>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
      <FormTambahReward />
      <FormEditReward />
      <FormDelete />
    </>
  );
};

export default Reward;
