import React from "react";
import HeaderDashboard from "../../parts/HeaderDashboard";
import {
  Konten,
  MessageRecived,
  MessageSent,
  Button,
  ButtonSend,
} from "../../parts/HeaderDashboard/Style";
import LeftBar from "../../parts/LeftBar";
import { UserTwo, UserOne, Send, Paperclip } from "../../parts/Assets";

if (window.location.href.substr(-2) !== "?r") {
  window.location = window.location.href + "?r";
}
const Chat = () => {
  return (
    <>
      <LeftBar />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8" id="chat">
                  <MessageRecived>
                    <p className="chat img-raised">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English.?
                      <br />
                      <span className="text-muted float-right mt-2">
                        08 Des 2020 07:00pm
                      </span>
                    </p>
                    <div className="name">
                      <img
                        src={UserTwo.url}
                        alt={UserTwo.alt}
                        className="rounded-circle shadow mr-3"
                        width="50px"
                        height="50px"
                      />
                      <b>Reynaldi</b>
                    </div>
                  </MessageRecived>
                  <MessageSent>
                    <p className="chat img-raised">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      <br />
                      <span className="text-muted float-right mt-2">
                        08 Des 2020 07:00pm
                      </span>
                    </p>
                    <div className="name float-right">
                      <b>Mangcepdev</b>
                      <img
                        src={UserOne.url}
                        alt={UserOne.alt}
                        className="rounded-circle shadow ml-3"
                        width="50px"
                        height="50px"
                      />
                    </div>
                  </MessageSent>
                  <div className="input-group mt-3">
                    <div className="input-group-prepend">
                      <Button type="button">
                        <img src={Paperclip.url} alt={Paperclip.alt} />
                      </Button>
                    </div>
                    <input
                      type="text"
                      className="form-control rounded"
                      placeholder="Enter text here..."
                    />
                    <div className="input-group-append">
                      <ButtonSend className="input-group-text">
                        <img src={Send.url} alt={Send.alt} />
                      </ButtonSend>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div id="rightChat">
                    <div className="header ml-3">
                      <h5>Quest Info</h5>
                      <p>431# Filling data entry/ Mengisi data entry</p>
                      <div className="row clearfix">
                        <div className="col-md-3">
                          <p>From</p>
                          <p>Points</p>
                          <p>Deadline</p>
                        </div>
                        <div className="col-md-9">
                          <p>: Reza</p>
                          <p>: 10</p>
                          <p>: 27 Des 2020</p>
                        </div>
                      </div>
                    </div>
                    <div className="body">
                      {/* accordion */}
                      <div
                        className="panel-group"
                        id="accordion_1"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="panel">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="headingOne_1"
                          >
                            <h4 className="panel-title">
                              <a
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion_1"
                                href="#collapseOne_1"
                                aria-expanded="true"
                                aria-controls="collapseOne_1"
                              >
                                <b>Description</b>
                              </a>
                              <hr />
                            </h4>
                          </div>
                          <div
                            id="collapseOne_1"
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby="headingOne_1"
                          >
                            <div className="panel-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                        <div className="panel">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="headingTwo_1"
                          >
                            <h4 className="panel-title">
                              <a
                                className="collapsed"
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion_1"
                                href="#collapseTwo_1"
                                aria-expanded="false"
                                aria-controls="collapseTwo_1"
                              >
                                <b>Instruction</b>
                              </a>
                              <hr />
                            </h4>
                          </div>
                          <div
                            id="collapseTwo_1"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo_1"
                          >
                            <div className="panel-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                        <div className="panel">
                          <div
                            className="panel-heading"
                            role="tab"
                            id="headingThree_1"
                          >
                            <h4 className="panel-title">
                              <a
                                className="collapsed"
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion_1"
                                href="#collapseThree_1"
                                aria-expanded="false"
                                aria-controls="collapseThree_1"
                              >
                                <b>Progress</b>
                              </a>
                              <hr />
                            </h4>
                          </div>
                          <div
                            id="collapseThree_1"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingThree_1"
                          >
                            <div className="panel-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* accordion end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
    </>
  );
};

export default Chat;
