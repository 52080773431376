import React from "react";
import { Link } from "react-router-dom";
import CardWorkspace from "../../parts/CardWorkspace";
import FormTambahWorkspace from "../../parts/FormTambahWorkspace";
import HeaderDashboard from "../../parts/HeaderDashboard";
import { Konten, Tombol } from "../../parts/HeaderDashboard/Style";
import LeftBar from "../../parts/LeftBar";

const Workspace = () => {
  const [todo, setTodo] = React.useState([]);
  const [proccess, setProcces] = React.useState([]);
  const [done, setDone] = React.useState([]);
  const inDone = () => {
    setDone([
      {
        id: 1,
        role: "Program Bootcamp 2 minggu",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Completed, 12 Des 2020",
      },
      {
        id: 2,
        role: "Marketing",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Completed, 12 Des 2020",
      },
      {
        id: 3,
        role: "Program Bootcamp 2 minggu",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "completed, 12 Des 2020",
      },
    ]);
  };
  const todos = () => {
    setTodo([
      {
        id: 1,
        role: "Back-End Developer",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Due date 12 Des 2020",
      },
      {
        id: 2,
        role: "Program Pelatihan 1 Bulan",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Due date 12 Des 2020",
      },
      {
        id: 3,
        role: "Test Team",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Due date 12 Des 2020",
      },
    ]);
  };
  const inProcces = () => {
    setProcces([
      {
        id: 1,
        role: "Back-End Developer",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "2 days left",
      },
      {
        id: 2,
        role: "Program Pelatihan 1 bulan",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "5 Days left",
      },
      {
        id: 3,
        role: "Marketing",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "1 month to complete",
      },
      {
        id: 4,
        role: "Program Boocamp 2 minggu",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "1 weeks more",
      },
      {
        id: 5,
        role: "Operasional",
        desc:
          "Lorem ipsum dolor sit amet, consecture adapting elit. Maecenas voris tortur nibh, sit amet tempor",
        status: "Hurry up! 2 Hours Left",
      },
    ]);
  };
  React.useEffect(() => {
    todos();
    inProcces();
    inDone();
  }, []);

  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar menuActive="workspace" />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              {/* konten header */}
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    <b id="title">Workspace</b>
                    <br />
                    PT. Lorem Ipsum
                  </p>
                </div>
                <Tombol className="col-lg-5">
                  <button
                    id="button"
                    className="btn float-right"
                    type="button"
                    data-toggle="modal"
                    data-target="#tambahWorkspace"
                  >
                    Tambah Workspace
                  </button>
                  {/* konten header akhir*/}
                </Tombol>
                <div className="col-lg-12">
                  <h3>To Do</h3>
                  <div className="row clearfix">
                    {todo.map((to) => (
                      <div className="col-md-4" key={to.id}>
                        <Link to="/chat" className="text-dark">
                          <CardWorkspace
                            idCard="todo"
                            role={to.role}
                            desc={to.desc}
                            status={to.status}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-12">
                  <h3>Proccess</h3>
                  <div className="row clearfix">
                    {proccess.map((r) => (
                      <div className="col-md-4" key={r.id}>
                        <Link to="/chat" className="text-dark">
                          <CardWorkspace
                            idCard="proccess"
                            role={r.role}
                            desc={r.desc}
                            status={r.status}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-12">
                  <h3>Done</h3>
                  <div className="row clearfix">
                    {done.map((d) => (
                      <div className="col-md-4" key={d.id}>
                        <Link to="/chat" className="text-dark">
                          <CardWorkspace
                            idCard="done"
                            role={d.role}
                            desc={d.desc}
                            status={d.status}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
      {/* modal */}
      <FormTambahWorkspace />
    </>
  );
};

export default Workspace;
