import styled from "styled-components";

export const MessageRecived = styled.div`
  margin-bottom: 30px;
  chat,
  .name {
    color: #4162fb;
  }
  color: #4162fb;
  .chat {
    width: 400px;
    height: auto;
    background: #ffffff;
    padding: 20px 20px 50px 20px;
    border-radius: 50px 50px 50px 0px;
    @media only screen and (max-width: 420px) {
      width: 300px;
      font-size: 10px;
    }
  }
`;

export const MessageSent = styled.div`
  float: right;
  margin-bottom: 30px;
  color: #4162fb;
  .name {
    color: #4162fb;
  }
  .chat {
    color: #ffffff;
    width: 400px;
    height: auto;
    background: #f9ac0e;
    padding: 20px 20px 50px 20px;
    border-radius: 50px 50px 0px 50px;
    @media only screen and (max-width: 420px) {
      width: 300px;
      font-size: 10px;
    }
  }
`;

export const Button = styled.button`
  border: none;
  background-color: #fffbf1;
`;

export const ButtonSend = styled.span`
  border: none;
  background-color: #fffbf1;
`;

export const Konten = styled.section`
  #rightChat {
    margin-left: 5px;
    padding-top: 20px;

    background-color: #f9f2ec;
    @media only screen and (max-width: 420px) {
      margin-top: 20px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  #chat {
    padding-top: 20px;
    padding-left: 30px;
    background: #fffbf1;
    padding-bottom: 50px;
  }
  #button {
    background: #f9ac0e !important;
    color: black;
    font-style: Poppins Bold;
    @media only screen and (max-width: 420px) {
      margin-top: -60px;
    }
  }
  #title {
    font-size: 22px;
  }
  #card {
    background-color: #ffe8b1;
    padding-top: 20px;
    margin-bottom: 20px;
    span {
      font-size: 10px;
      margin-top: 40px;
      margin-bottom: 40px !important;
    }
  }
  #back {
    background-color: #fffbf1;
    padding-top: 120px;
    margin-top: -130px;
  }
  #rightSide {
    padding-left: 30px;
  }
  font-family: "Poppins Regular";
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  //   -webkit-border-radius: 10px;
  //   -moz-border-radius: 10px;
  //   -ms-border-radius: 10px;
  //   border-radius: 10px;
  background: none;
  //   border: 5px solid #fff;
  padding: 20px;
  position: relative;
  margin: 0px 0px 20px 70px;
  @media screen and (max-width: 420px) {
    margin: -5px 0px 20px 0px;
  }
`;

export const Tombol = styled.div`
  padding-top: 100px !important,
  padding-left: 30px,
`;
