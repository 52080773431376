import React from "react";

const CardWorkspace = ({ idCard, role, desc, status }) => {
  return (
    <div
      className="card"
      style={{
        backgroundColor:
          idCard === "proccess"
            ? "#F9F2EC"
            : idCard === "done"
            ? "#FFE8B1"
            : "white",
        paddingLeft: "20px",
      }}
    >
      <div
        style={{
          borderLeft:
            idCard === "todo" ? "2px solid #FFE8B1" : "2px solid #F9AC0E",
          paddingLeft: "10px",
        }}
      >
        <h4>{role}</h4>
        <p>{desc}</p>
        <p>
          <b>{status}</b>
        </p>
      </div>
    </div>
  );
};

export default CardWorkspace;
