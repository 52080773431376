import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { UserOne } from "../Assets";
import { Konten } from "./Style";

const Span = styled.span`
  position: absolute;
  margin-left: 10px;
  padding-bottom: 0px !important;
`;

const Bel = styled.div`
  @media screen and (max-width: 420px) {
    padding-top: 25px;
    padding-bottom: 0px !important;
    margin-bottom: -35px;
    z-index: 100;
  }
`;

const Input = styled.input`
  border: none;
`;

const HeaderDashboard = () => {
  return (
    <Konten>
      <div className="container" style={{ zIndex: "3", position: "relative" }}>
        <div className="row">
          <div className="col-lg-9 col-md-6 col-sm-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="zmdi zmdi-search"></i>
                </span>
              </div>
              <Input
                type="text"
                className="form-control timepicker"
                placeholder="Search"
              />
            </div>
            <button className="btn bg-black btn-icon mobile_menu" type="button">
              <i className="zmdi zmdi-sort-amount-desc"></i>
            </button>
          </div>
          <Bel className="col-lg-2 col-md-2 col-sm-4 bel">
            <div className="navbar-right float-right">
              <div className="dropdown">
                <a
                  href={{ javascript: void 0 }}
                  className="dropdown-toggle "
                  title="Notifications"
                  data-toggle="dropdown"
                  role="button"
                >
                  <Span className="zmdi zmdi-circle zmdi-hc-1x mt-1 text-danger"></Span>
                  <i className="zmdi zmdi-notifications-none zmdi-hc-2x text-dark mr-2 mt-2"></i>
                </a>
                <ul
                  className="dropdown-menu slideUp3"
                  style={{ marginRight: "250px" }}
                >
                  <li className="header">Notifications</li>
                  <li className="body">
                    <ul className="menu list-unstyled">
                      <li>
                        <a href={{ javascript: void 0 }}>
                          <div className="menu-info">
                            <h4>8 New Members joined</h4>
                            <p>
                              <i className="zmdi zmdi-time"></i> 14 mins ago{" "}
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href={{ javascript: void 0 }}>
                          <div className="menu-info">
                            <h4>9 New Members joined</h4>
                            <p>
                              <i className="zmdi zmdi-time"></i> 30 mins ago
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="footer">
                    <a href={{ javascript: void 0 }}>View All Notifications</a>
                  </li>
                </ul>
              </div>
            </div>
          </Bel>
          <div className="col-lg-1 col-md-4 col-sm-2 float-right">
            <Link to="/profile">
              <img
                src={UserOne.url}
                alt={UserOne.url}
                className="rounded-circle"
                width="45px"
                height="auto"
              />
            </Link>
          </div>
        </div>
      </div>
    </Konten>
  );
};

export default HeaderDashboard;
