import React from "react";
import LeftBar from "../../parts/LeftBar";
import { Konten } from "../../parts/HeaderDashboard/Style";
import HeaderDashboard from "../../parts/HeaderDashboard";
import {
  CoachOne,
  CoachTwo,
  CoachThree,
  CoachFour,
  CoachFive,
  Coachsix,
  CoachSeven,
  CoachEight,
} from "../../parts/Assets";
import CardCoach from "../../parts/CardCoach";

const CoachingPrograms = () => {
  const [coach, setCoach] = React.useState([]);
  const addCoach = () => {
    setCoach([
      {
        id: 1,
        pict: CoachOne,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 2,
        pict: CoachTwo,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 3,
        pict: CoachThree,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 4,
        pict: CoachFour,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 5,
        pict: CoachFive,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 6,
        pict: Coachsix,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 7,
        pict: CoachSeven,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
      {
        id: 8,
        pict: CoachEight,
        desc:
          "lorem ipsum dolor sit amet conse cretur adipsicing alit. Maecenas vaius tortor nibh. sit amet tempor nibh finibus et.",
      },
    ]);
  };

  React.useEffect(() => {
    addCoach();
  }, []);

  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar menuActive="coaching-program" />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              {/* konten header */}
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    <b id="title">Coaching Program</b>
                    <br />
                    <b>Learn From The Best</b>
                    <br />
                    Experience our unique Coaching Programming for indivduals
                  </p>
                  <div className="row clearfix">
                    {coach.map((co) => (
                      <div className="col-lg-3" key={co.id}>
                        <CardCoach
                          pict={co.pict.url}
                          alt={co.pict.alt}
                          desc={co.desc}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
    </>
  );
};

export default CoachingPrograms;
