import styled from "styled-components";
import { BackgroundImage, BackgroundFooter } from "../../parts/Assets";

export const Head = styled.section`
  padding-top: 100px;
  padding-bottom: 0px;
  background-image: url("${BackgroundImage.url}");
  background-repeat: no-repeat;
  background-size: 100% 105%;
  font-family: Poppins Regular;
  font-size: 1.2rem;
  h1 {
    font-family: Poppins Bold;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 420px) {
    background-size: 100% 115%;
    h1 {
      font-size: 1.8rem;
    }
    .ht {
      margin-bottom: 60px;
    }
    .head {
      margin-top: -100px;
    }
  }
`;

export const Foot = styled.section`
  background-image: url("${BackgroundFooter.url}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: Poppins Regular;
  padding-bottom: 45px;
  padding-top: 45px;

  @media screen and (max-width: 420px) {
    background-size: 120% 100%;
  }
`;
