import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LogoMastery, Icons } from "../Assets";

const Menu = styled.li`
  margin-left: 18px;
  p {
    font-size: 10px;
    // display: none;
    font-family: Poppins Bold;
    visibility: hidden;
    margin-bottom: -25px;
  }
  :hover .icon {
    color: #f9ac0e !important;
  }
  :hover p.user,
  :hover p.team {
    visibility: visible;
    z-index: 2000;
    margin: auto;
    margin-left: 0px;
    display: block !important;
    color: #f9ac0e;
    display: block;
  }
  :hover p {
    visibility: visible;
    z-index: 2000;
    margin: auto;
    margin-left: -10px;
    display: block !important;
    color: #f9ac0e;
    display: block;
  }
`;
const LeftBar = ({ menuActive, ubah }) => {
  const [backGroundsideBar, setbackGroundsideBar] = React.useState(false);
  const ubahBackground = () => {
    setbackGroundsideBar(ubah);
  };
  React.useEffect(() => {
    ubahBackground();
  });
  return (
    <aside
      id="leftsidebar"
      className="sidebar"
      style={{
        width: "110px",
        background: !backGroundsideBar ? "#F9F2EC" : "white",
      }}
    >
      <div className="navbar-brand">
        <Link to="/">
          <img
            src={LogoMastery.url}
            width="25"
            alt={LogoMastery.alt}
            className="active text-red ml-4"
          />
        </Link>
      </div>
      <div className="menu" style={{ paddingTop: "100px" }}>
        <ul className="list">
          <Menu>
            <Link to="/dashboard">
              <Icons.FiHome
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: menuActive === "dashboard" ? "#F9AC0E" : "black",
                }}
              />
              <p>Dashboard</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="/users">
              <Icons.FiUser
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: menuActive === "users" ? "#F9AC0E" : "black",
                }}
              />
              <p className="user">User</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="/teams">
              <Icons.FiUsers
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: menuActive === "teams" ? "#F9AC0E" : "black",
                }}
              />
              <p className="team">Teams</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="/workspace">
              <Icons.FiBriefcase
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: menuActive === "workspace" ? "#F9AC0E" : "black",
                }}
              />
              <p>Workspace</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="/reward">
              <Icons.FiGift
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: menuActive === "reward" ? "#F9AC0E" : "black",
                }}
              />
              <p>Reward</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="/coaching-programs">
              <Icons.FiTrello
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color:
                    menuActive === "coaching-program" ? "#F9AC0E" : "black",
                }}
              />
              <p>Coaching Programs</p>
            </Link>
          </Menu>
          <Menu>
            <Link to="#">
              <Icons.FiLogOut
                className="icon"
                style={{
                  width: "25px",
                  height: "auto",
                  color: "black",
                }}
              />
              <p>Logout</p>
            </Link>
          </Menu>
        </ul>
      </div>
    </aside>
  );
};

export default LeftBar;
