import React from "react";
import HeaderDashboard from "../../parts/HeaderDashboard";
import LeftBar from "../../parts/LeftBar";
import { Konten } from "../../parts/HeaderDashboard/Style";
import CardDashboard from "../../parts/CardDashboard";
import { Line } from "react-chartjs-2";

const Dashboard = () => {
  const [chartData, setChartData] = React.useState({});
  const [report, setReport] = React.useState([]);

  const reportEmployee = () => {
    setReport([
      { id: 1, title: "Achievments", subtitle: "13 Quests" },
      {
        id: 2,
        title: "Productivity",
        subtitle: "5.9 hour/Quest",
      },
      { id: 3, title: "Time Loss", subtitle: "48.13 Hours" },
      { id: 4, title: "Quest Missed", subtitle: "2" },
      { id: 5, title: "Time Effeciency", subtitle: "59.05 %" },
      {
        id: 6,
        title: "Quest Effeciency",
        subtitle: "1.92 Points/Quest",
      },
      { id: 7, title: "Effectivity", subtitle: "41.94%" },
      {
        id: 8,
        title: "Overall Performance",
        subtitle: "AVERAGE",
      },
    ]);
  };

  const chart = () => {
    setChartData({
      labels: [0, 5, 10, 15, 20, 25, 30, 35],
      datasets: [
        {
          label: "Line One",
          data: [2.0, 2.8, 2.5, 1, 2.9, 2.0, 2.3, 1.2],
          backgroundColor: ["#4162fb"],
          borderWidth: 0,
        },
      ],
    });
  };

  React.useEffect(() => {
    reportEmployee();
    chart();
  }, []);

  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar ubah={true} menuActive="dashboard" />
      {/* header */}
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              <div className="row">
                {/* konten header */}
                <div className="col-lg-7" id="back">
                  <div className="row clearfix">
                    <div className="col-md-5 col-sm-5">
                      <p>
                        <b id="title">Dashboard</b>
                        <br />
                        PT. Lorem Ipsum
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <select className="form-control show-tick shadow">
                        <option value="Januari">Januari</option>
                        <option value="Februari">Februari</option>
                        <option value="Maret">Maret</option>
                        <option value="April">April</option>
                        <option value="Mei">Mei</option>
                        <option value="Juni">Juni</option>
                        <option value="Juli">Juli</option>
                        <option value="Agustus">Agustus</option>
                        <option value="September">September</option>
                        <option value="Oktober">Oktober</option>
                        <option value="November">November</option>
                        <option value="Desember">Desember</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <button
                        className="btn mb-4 shadow"
                        style={{
                          backgroundColor: "#F9AC0E",
                          color: "black",
                          fontStyle: "Poppins Bold",
                        }}
                      >
                        Download Report
                      </button>
                    </div>
                    {report.map((card) => (
                      <div className="col-md-3" key={card.id}>
                        <CardDashboard
                          title={card.title}
                          subtitle={card.subtitle}
                        />
                      </div>
                    ))}

                    <h5>Daily Quests</h5>
                    <Line
                      data={chartData}
                      options={{
                        responsive: true,
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                beginAtZero: true,
                              },
                              gridLines: {
                                display: false,
                              },
                            },
                          ],
                        },
                      }}
                    />
                    <h5>Daily Quests</h5>
                    <Line
                      data={chartData}
                      options={{
                        responsive: true,
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                beginAtZero: true,
                              },
                              gridLines: {
                                display: false,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </div>
                </div>
                {/* konten header akhir*/}
                <div className="col-lg-5" id="rightSide">
                  <b>Backend Developer</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "75%" },
                      { id: 2, title: "Effectivity", value: "50%" },
                      { id: 3, title: "Quest Efficiency", value: "25 %" },
                      { id: 4, title: "Time Efficiency", value: "65 %" },
                    ].map((c) => (
                      <div className="col-lg-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />
                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <b>HR</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "65 %" },
                      { id: 2, title: "Effectivity", value: "25 %" },
                      { id: 3, title: "Quest Efficiency", value: "50 %" },
                      { id: 4, title: "Time Efficiency", value: "75 %" },
                    ].map((c) => (
                      <div className="col-md-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />

                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <b>Admin</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "75 %" },
                      { id: 2, title: "Effectivity", value: "50 %" },
                      { id: 3, title: "Quest Efficiency", value: "25 %" },
                      { id: 4, title: "Time Efficiency", value: "65 %" },
                    ].map((c) => (
                      <div className="col-md-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />

                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <b>Manajemen</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "65 %" },
                      { id: 2, title: "Effectivity", value: "25 %" },
                      { id: 3, title: "Quest Efficiency", value: "50 %" },
                      { id: 4, title: "Time Efficiency", value: "75 %" },
                    ].map((c) => (
                      <div className="col-md-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />

                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <b>Front-End Developer</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "65 %" },
                      { id: 2, title: "Effectivity", value: "25 %" },
                      { id: 3, title: "Quest Efficiency", value: "50 %" },
                      { id: 4, title: "Time Efficiency", value: "75 %" },
                    ].map((c) => (
                      <div className="col-md-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />

                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <b>Test Team</b>
                  <div className="row" id="card">
                    {[
                      { id: 1, title: "Productivity", value: "65 %" },
                      { id: 2, title: "Effectivity", value: "25 %" },
                      { id: 3, title: "Quest Efficiency", value: "50 %" },
                      { id: 4, title: "Time Efficiency", value: "75 %" },
                    ].map((c) => (
                      <div className="col-md-3" key={c.id}>
                        <div className="body text-center">
                          <input
                            type="text"
                            className="knob"
                            data-linecap="round"
                            value={c.value}
                            data-width="80"
                            data-height="80"
                            data-thickness="0.25"
                            data-fgcolor="#F9AC0E"
                            readOnly
                          />
                          <br />
                          <span>{c.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
    </>
  );
};

export default Dashboard;
