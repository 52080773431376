import React from "react";
import { useForm } from "react-hook-form";

const FormEditReward = () => {
  const [editReward, seteditReward] = React.useState({});
  const { register, handleSubmit, errors } = useForm();
  const submit = (editreward) => {
    seteditReward(editreward);
  };
  console.log(editReward);
  return (
    <div
      className="modal fade"
      id="editReward"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Edit Reward
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="editnama">Nama</label>
                <input
                  name="editnama"
                  ref={register({ required: true })}
                  type="text"
                  id="editnama"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editnama && (
                  <span className="text-danger text-muted mb-2">
                    nama harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editdeskripsi">Deskripsi</label>
                <div className="form-line">
                  <textarea
                    rows="4"
                    name="editdeskripsi"
                    ref={register({ required: true })}
                    className="form-control no-resize"
                    style={{ backgroundColor: "#FFE8B1" }}
                  ></textarea>
                </div>

                {errors.editdeskripsi && (
                  <span className="text-danger text-muted mb-2">
                    Deskripsi harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editpoint">Poin</label>
                <input
                  name="editpoint"
                  ref={register({ required: true })}
                  type="number"
                  id="editpoint"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editpoint && (
                  <span className="text-danger text-muted mb-2">
                    Poin harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editstock">Stock</label>
                <input
                  name="editstock"
                  ref={register({ required: true })}
                  type="number"
                  id="editstock"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editstock && (
                  <span className="text-danger text-muted mb-2">
                    Stock harus diisi
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
              >
                <b>Simpan</b>
              </button>
              <button
                type="button"
                style={{
                  marginRight: "30px",
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
                data-dismiss="modal"
              >
                Batalkan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditReward;
