import React from "react";
import styled from "styled-components";

const Style = styled.div`
  font-family: "Poppins Bold";
  ul {
    margin-left: 50px;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .number {
    padding-top: 0px;
    margin-top: -40px;
    margin-bottom: -20px;
    font-size: 60px;
  }
  .member,
  .number,
  .desc {
    color: #f9ac0e;
  }
`;

const CardTeam = ({
  prop,
  alt,
  position,
  role,
  member,
  description,
  productivity,
  questEffectivity,
  effectivity,
  timeRfficiency,
}) => {
  return (
    <Style>
      <div className="row clearfix shadow">
        <div
          className="col-md-2"
          style={{
            backgroundColor: "#F9F2EC",
          }}
        >
          <div className="card ml-1 pt-5">
            <img
              src={prop}
              alt={alt}
              className="rounded-circle shadow"
              width="80px"
              height="auto"
            />
            <p className="mt-3">
              {position} <br />
              {role}
            </p>
          </div>
        </div>
        <div
          className="col-md-2"
          style={{
            backgroundColor: "#FFE8B1",
          }}
        >
          <div className="card pt-5">
            <ul className="list">
              <li className="member">Member</li>
              {member.map((m, index) => (
                <li key={index}>{m}</li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="col-md-8"
          style={{
            backgroundColor: "#F9F2EC",
          }}
        >
          <div className="card ml-3 pt-4 pr-4">
            <p className="desc">Description</p>
            <p>{description}</p>
          </div>
          <div className="row clearfix text-center">
            <div className="col-sm-3">
              <p className="number">{productivity} </p>
              <p>Productivity </p>
            </div>
            <div className="col-sm-3">
              <p className="number">{questEffectivity}</p>
              <p>Quest Efficiency </p>
            </div>
            <div className="col-sm-3">
              <p className="number">{effectivity}</p>
              <p>Effectivity </p>
            </div>
            <div className="col-sm-3">
              <p className="number">{timeRfficiency} </p>
              <p>Time Efficiency </p>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default CardTeam;
