import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Chat from "../Chat";
import CoachingPrograms from "../CoachingPrograms";
import Dashboard from "../Dashboard";
import Home from "../Home";
import Reward from "../Reward";
import Teams from "../Teams";
import Users from "../Users";
import Workspace from "../Workspace";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/users" component={Users} />
        <Route path="/teams" component={Teams} />
        <Route path="/workspace" component={Workspace} />
        <Route path="/reward" component={Reward} />
        <Route path="/coaching-programs" component={CoachingPrograms} />
        <Route path="/chat" component={Chat} />
      </Switch>
    </Router>
  );
};

export default App;
