import React from "react";
import { useForm } from "react-hook-form";

const FormTambahUser = () => {
  const [addUser, setAddUser] = React.useState({});
  const { register, handleSubmit, errors } = useForm();
  const submit = (userInput) => {
    setAddUser(userInput);
  };
  console.log(addUser);
  return (
    <div
      className="modal fade"
      id="tambahUser"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Tambah User
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="nama">Nama</label>
                <input
                  name="nama"
                  ref={register({ required: true })}
                  type="text"
                  id="nama"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.nama && (
                  <span className="text-danger text-muted mb-2">
                    nama harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  //   ref={register({ required: true })}
                  type="email"
                  id="email"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.email && (
                  <span className="text-danger text-muted mb-2">
                    email harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  ref={register({ required: true })}
                  type="number"
                  id="phone"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.phone && (
                  <span className="text-danger text-muted mb-2">
                    phone harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  name="password"
                  ref={register({ required: true })}
                  type="password"
                  id="password"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.password && (
                  <span className="text-danger text-muted mb-2">
                    email harus diisi
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
              >
                <b>Simpan</b>
              </button>
              <button
                type="button"
                style={{
                  marginRight: "30px",
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
                data-dismiss="modal"
              >
                Batalkan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTambahUser;
