import React from "react";
import { Star } from "../Assets";

const CardCoach = ({ pict, alt, desc }) => {
  return (
    <div className="card info-box-2">
      <div className="body">
        <div className="content col-12">
          <img
            src={pict}
            alt={alt}
            className="rounded img-raised"
            style={{
              display: "inline-block",
              width: "auto",
              height: "auto",
              verticalAlign: "top",
            }}
          />
          <div className="text-left mt-3">{desc}</div>
          <div className="text-left mt-2">
            <img src={Star.url} alt={Star.alt} />
            <img src={Star.url} alt={Star.alt} />
            <img src={Star.url} alt={Star.alt} />
            <img src={Star.url} alt={Star.alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCoach;
