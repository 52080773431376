import React from "react";

const CardDashboard = ({title,subtitle}) => {
  return (
    <div className="card rounded" style={{fontFamily: 'Poppins Bold', fontSize:'12px', backgroundColor: "#F9F2EC" }}>
      <div className="text-center mt-3 pt-1">
        <p className="text-black">{title}</p>
        <p style={{ color: "#F9AC0E" }}>{subtitle}</p>
      </div>
    </div>
  );
};

export default CardDashboard;
