import React, { useState } from "react";
import styled from "styled-components";

import CardNumber from "../CardNumber";
import { UseCaseImages } from "../Assets";

const Content = styled.section`
  margin-top: 50px;
  font-size: 18px;
  p.main {
    font-family: Poppins Regular;
  }

  @media screen and (max-width: 420px) {
    margin-left: 15px;
    margin-right: 25px;
    img.banner {
      margin-left: -50px;
    }
  }
`;

const ContentOne = () => {
  const [content] = useState([
    {
      number: "1",
      title: "Memantau tugas yang diberikan",
      subtitle:
        "Distribusi tugas, status pengerjaan, kendala yang terjadi, sampai pada kecepatan dan produktivitas individu/tim yang mengerjakan.",
    },
    {
      number: "2",
      title: "Memastikan pencapaian target",
      subtitle:
        "Kecepatan dan produktivitas individu/tim, kualitas hasil pekerjaan, dan support yang dibutuhkan.",
    },
    {
      number: "3",
      title: "Melakukan evaluasi objektif",
      subtitle:
        "Atas hasil pencapaian, kinerja, loyalitas, dan kualitas pekerjaan, berbasis data yang sifatnya obyektif dan real-time.",
    },
  ]);

  return (
    <Content
      className="container"
      data-aos="fade-up"
      style={{ marginBottom: 100 }}
    >
      <div className="row clearfix" data-aos="fade-up">
        <div className="col-lg-12 mt-4 mb-4">
          <h3>Feature</h3>
          <p className="main">
            <strong>Mastery</strong>, sebuah tools digital untuk memudahkan
            manajemen kinerja perusahaan, untuk distribusi, monitoring, dan
            evaluasi individu dan tim di perusahaan.
          </p>
        </div>
        {content.map((cont, index) => (
          <div className="col-lg-4" key={index} data-aos="fade-up">
            <CardNumber
              number={cont.number}
              title={cont.title}
              subtitle={cont.subtitle}
            />
          </div>
        ))}
        <div
          className="col-lg-12 mt-4"
          style={{ marginTop: 100 }}
          data-aos="fade-up"
        >
          <h3 className="text-center" style={{ marginTop: 100 }}>
            Use Case
          </h3>
        </div>
        <div className="col-lg-6" data-aos="fade-up">
          <img
            src={UseCaseImages.url}
            alt={UseCaseImages.alt}
            className="banner"
          />
        </div>
        <div className="col-lg-6" data-aos="fade-up">
          <div className="py-auto my-auto">
            <CardNumber
              title="Perusahaan catering, karyawan 10 orang, ingin kembangkan bisnis tapi tim perlu dipantau setiap hari."
              subtitle="Gunakan Mastery, distribusikan tugas setiap hari, pantau pengerjaan tugas tim dari dashboard, lihat bukti-bukti yang sifatnya real-time, pastikan indikator pencapaian target terpenuhi oleh tim.
              "
            />
            <br />
            <CardNumber
              title="Perusahaan EO, karyawan 16 orang, perlu kejar PO, tim masih hijau masih butuh pengarahan.
              "
              subtitle="Mastery solusinya, dengan sistem penugasan yang jelas, monitoring hasil terpadu, sarana untuk berikan review dan feedback efektif, untuk memastikan goal terpenuhi, klien happy              ."
            />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ContentOne;
