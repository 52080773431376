import React from "react";
import { useForm } from "react-hook-form";

const FormTambahReward = () => {
  const [tambahReward, setTambahReward] = React.useState({});
  const { register, handleSubmit, errors } = useForm();
  const submit = (reward) => {
    setTambahReward(reward);
  };
  console.log(tambahReward);
  return (
    <div
      className="modal fade"
      id="tambahReward"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Tambah Reward
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="namaReward">Nama</label>
                <input
                  name="namaReward"
                  ref={register({ required: true })}
                  type="text"
                  id="namaReward"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.namaReward && (
                  <span className="text-danger text-muted mb-2">
                    nama harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="deskripsi">Deskripsi</label>
                <div className="form-line">
                  <textarea
                    rows="4"
                    name="deskripsi"
                    ref={register({ required: true })}
                    className="form-control no-resize"
                    style={{ backgroundColor: "#FFE8B1" }}
                  ></textarea>
                </div>

                {errors.deskripsi && (
                  <span className="text-danger text-muted mb-2">
                    Deskripsi harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="point">Poin</label>
                <input
                  name="point"
                  ref={register({ required: true })}
                  type="number"
                  id="point"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.point && (
                  <span className="text-danger text-muted mb-2">
                    Poin harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="stock">Stock</label>
                <input
                  name="stock"
                  ref={register({ required: true })}
                  type="stock"
                  id="stock"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.stock && (
                  <span className="text-danger text-muted mb-2">
                    Stock harus diisi
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
              >
                <b>Simpan</b>
              </button>
              <button
                type="button"
                style={{
                  marginRight: "30px",
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
                data-dismiss="modal"
              >
                Batalkan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTambahReward;
