import React from "react";
import { Icons } from "../Assets";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Logo = styled.div`
  a {
    color: black;
  }

  a:hover {
    color: white;
  }
  font-size: 50px;
  padding-left: 0px;
  text-color: "black";
`;
const Features = styled.div`
  margin: 10px;
`;

const FeaturesItem = styled.a`
  color: black;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
`;

const Copyrights = styled.p`
  margin: 7px 0px;
  font-size: 11px;
`;

const Footer = () => {
  return (
    <section className="container" data-aos="fade-up">
      <div className="row clearfix pt-5 pb-5">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <center>
            <h4 style={{ marginBottom: 60, marginTop: 60 }}>
              Tunggu apa lagi.. Distribusi kerjaan gampang, monitoring kerjaan
              tim simpel, dan evaluasi kinerja obyektif. Gabung sekarang untuk
              tim tertib, bisnis melejit!
            </h4>

            <a
              href="https://my.masteryapps.id"
              className="registerButton text-dark mt-4 mr-4 px-4 py-1"
            >
              Coba Sekarang
            </a>
          </center>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-6  mt-5">
          <h1>
            Need help with
            <br />
            anything?
          </h1>
          <p>
            Jl. Paus dalam No. 37, <br />
            Rawamangun
            <br />
            Pulo Gadung Indonesia,13220
          </p>
          <p>
            021-4721536
            <br />
            mastery.maximus@gmail.com
          </p>
        </div>
        <div className="col-lg-6 mt-4">
          <div className="row">
            <div className={`col-md-12`}>
              <h4>Follow Us</h4>
              <Logo>
                {/* <Link to="#" style={{ marginRight: 15 }}>
                  <Icons.FaFacebook />
                </Link> */}
                <a
                  href="https://www.instagram.com/mastery.apps/"
                  target="_blank"
                >
                  <Icons.FaInstagram />{" "}
                  <span style={{ fontSize: "1.2rem" }}>@mastery.apps</span>
                </a>
              </Logo>
            </div>
            {/*}
            <div className="col-md-4 mt-4">
              <h5>Why Mastery?</h5>
            </div>
            <div className="col-md-4 mt-4">
              <h5>Features</h5>
              <Features>
                <FeaturesItem href="#">Teamwork</FeaturesItem>
              </Features>
              <Features>
                <FeaturesItem href="#">Project Management</FeaturesItem>
              </Features>
              <Features>
                <FeaturesItem href="#">By Industries</FeaturesItem>
              </Features>
            </div>
            <div className="col-md-4 mt-4">
              <h5>Pricing</h5>
            </div>
  {*/}
          </div>
        </div>

        <div className="row clearfix container-fluid">
          <div className="col-lg-12">
            <Copyrights>Hak Cipta 2021 PT Narapatih Inspiratama.</Copyrights>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
