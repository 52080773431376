// dokumentasi : https://react-icons.github.io/

import { FaFacebook, FaInstagram } from "react-icons/fa";
import {
  FiHome,
  FiUser,
  FiUsers,
  FiBriefcase,
  FiGift,
  FiTrello,
  FiLogOut,
} from "react-icons/fi";
import home from "./img/icons/home.svg";
import user from "./img/icons/user.svg";
import users from "./img/icons/users.svg";
import briefcase from "./img/icons/briefcase.svg";
import gift from "./img/icons/gift.svg";
import trello from "./img/icons/trello.svg";
import logOut from "./img/icons/log-out.svg";

// image

import Logo from "./img/logo.png";
import Background from "./img/Background.png";
import BannderImage from "./img/banner.png";
import FooterBackground from "./img/FooterBackground.png";
import UseCase from "./img/UseCaseImage.png";
import Prev from "./img/Arrow Left.png";
import Next from "./img/Arrow Right.png";
import Article1 from "./img/article-1.png";
import Article2 from "./img/article-2.png";
import Article3 from "./img/article-3.png";
import RightArrowArticle from "./img/rightArrowArticle.png";
import logoMastery from "./img/Logo Mastery.png";
import userOne from "./img/useone.png";
import userTwo from "./img/usertwo.png";
import userThree from "./img/userthree.png";
import userFour from "./img/userfour.png";
import coachone from "./img/coach_1.jpg";
import coachtwo from "./img/coach_2.jpg";
import coachthree from "./img/coach_3.jpg";
import coachfour from "./img/coach_4.jpg";
import coachfive from "./img/coach_5.jpg";
import coachsix from "./img/coach_6.jpg";
import coachseven from "./img/coach_7.jpg";
import coacheight from "./img/coach_8.jpg";
import star from "./img/Star 1.png";
import paperclip from "./img/icons/paperclip.png";
import send from "./img/icons/send.png";

// images
export const Star = {
  url: star,
  alt: "star",
};
export const CoachEight = {
  url: coacheight,
  alt: "coach eight",
};
export const CoachSeven = {
  url: coachseven,
  alt: "coach seven",
};
export const Coachsix = {
  url: coachsix,
  alt: "coach six",
};
export const CoachFive = {
  url: coachfive,
  alt: "coach five",
};
export const CoachFour = {
  url: coachfour,
  alt: "coach four",
};
export const CoachThree = {
  url: coachthree,
  alt: "coach three",
};
export const CoachTwo = {
  url: coachtwo,
  alt: "coach two",
};
export const CoachOne = {
  url: coachone,
  alt: "coach one",
};

export const UserFour = {
  url: userFour,
  alt: "user four",
};

export const UserTwo = {
  url: userTwo,
  alt: "usertwo.png",
};

export const UserThree = {
  url: userThree,
  alt: "user three",
};

export const UserOne = {
  url: userOne,
  alt: "user one",
};

export const LogoMastery = {
  url: logoMastery,
  alt: "Logo Mastery.png",
};

export const PrevButton = {
  url: Prev,
  alt: "Previous Button",
};

export const NextButton = {
  url: Next,
  alt: "Next Button",
};

export const UseCaseImages = {
  url: UseCase,
  alt: "Use case image",
};

export const Banner = {
  url: BannderImage,
  alt: "banner Image",
};

export const LogoImage = {
  url: Logo,
  alt: "logo image",
};

export const BackgroundImage = {
  url: Background,
  alt: "background images",
};

export const BackgroundFooter = {
  url: FooterBackground,
  alt: "background footer bergelombang",
};

export const ArticleOne = {
  url: Article1,
  slt: "article image one",
};

export const ArticleTwo = {
  url: Article2,
  slt: "article image two",
};

export const ArticleThree = {
  url: Article3,
  slt: "article image Three",
};

export const ArrowArticle = {
  url: RightArrowArticle,
  slt: "article rigth arrow image",
};

// icons

export const Icons = {
  FaFacebook,
  FaInstagram,
  FiHome,
  FiUser,
  FiUsers,
  FiBriefcase,
  FiGift,
  FiTrello,
  FiLogOut,
};

export const Send = {
  url: send,
  alt: "send",
};

export const Paperclip = {
  url: paperclip,
  alt: "paperclip",
};

export const Logout = {
  url: logOut,
  alt: "log-out.svg",
};

export const Trello = {
  url: trello,
  alt: "trello.svg",
};

export const Gift = {
  url: gift,
  alt: "gift.svg",
};

export const Briefcase = {
  url: briefcase,
  alt: "briefcase.svg",
};

export const Users = {
  url: users,
  alt: "user.svg",
};
export const User = {
  url: user,
  alt: "user.svg",
};

export const Home = {
  url: home,
  alt: "home.svg",
};
