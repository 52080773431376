import React from "react";
import { useForm } from "react-hook-form";

const FormEditUser = () => {
  const [editUser, setEditUser] = React.useState({});
  const { register, handleSubmit, errors } = useForm();
  const submit = (userInput) => {
    setEditUser(userInput);
  };
  console.log(editUser);
  return (
    <div
      className="modal fade"
      id="editUser"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Edit User
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="editnama">Nama</label>
                <input
                  name="editnama"
                  ref={register({ required: true })}
                  type="text"
                  id="editnama"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editnama && (
                  <span className="text-danger text-muted mb-2">
                    nama harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editemail">Email</label>
                <input
                  name="editemail"
                  ref={register({ required: true })}
                  type="email"
                  id="editemail"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editemail && (
                  <span className="text-danger text-muted mb-2">
                    email harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editPhone">Phone</label>
                <input
                  name="editPhone"
                  ref={register({ required: true })}
                  type="number"
                  id="editPhone"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editPhone && (
                  <span className="text-danger text-muted mb-2">
                    phone harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="editPassword">Password</label>
                <input
                  name="editPassword"
                  ref={register({ required: true })}
                  type="editPassword"
                  id="editPassword"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.editPassword && (
                  <span className="text-danger text-muted mb-2">
                    email harus diisi
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
              >
                <b>Simpan</b>
              </button>
              <button
                type="button"
                style={{
                  marginRight: "30px",
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
                data-dismiss="modal"
              >
                Batalkan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditUser;
