import React from "react";
import LeftBar from "../../parts/LeftBar";
import HeaderDashboard from "../../parts/HeaderDashboard";
import { Konten, Tombol } from "../../parts/HeaderDashboard/Style";
import FormTambahUser from "../../parts/FormTambahUser";
import FormEditUser from "../../parts/FormEditUser";
import FormDelete from "../../parts/FormDelete";

const Users = () => {
  const [user, setUser] = React.useState([]);

  const onSetUser = () => {
    setUser([
      { id: 1, name: "Rendy Tomoluweng", email: "workspace.rendy@gmail.com" },
      {
        id: 2,
        name: "Cecep Solihin Yusup",
        email: "cecepsolihinuyusup@gmail.com",
      },
      { id: 3, name: "Hana Frista Rusliana", email: "hanafrista@gmail.com" },
      { id: 4, name: "Muhammad Alfaqih", email: "alfaqih@gmail.com" },
      { id: 5, name: "Aulia Alfath", email: "emailUser@gmail.com" },
      { id: 6, name: "Rendy Tomoluweng", email: "workspace.rendy@gmail.com" },
      {
        id: 7,
        name: "Cecep Solihin Yusup",
        email: "cecepsolihinuyusup@gmail.com",
      },
      { id: 8, name: "Hana Frista Rusliana", email: "hanafrista@gmail.com" },
      { id: 9, name: "Muhammad Alfaqih", email: "alfaqih@gmail.com" },
      { id: 10, name: "Aulia Alfath", email: "emailUser@gmail.com" },
    ]);
  };

  React.useEffect(() => {
    onSetUser();
  }, []);

  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar menuActive="users" />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              {/* konten header */}
              <div className="row">
                <div className="col-lg-7 col-sm-5">
                  <p>
                    <b id="title">User</b>
                    <br />
                    PT. Lorem Ipsum
                  </p>
                </div>

                <Tombol className="tombol col-lg-5" id="rightSide">
                  <button
                    id="button"
                    className="btn mb-4 shadow float-right"
                    type="button"
                    data-toggle="modal"
                    data-target="#tambahUser"
                  >
                    Tambah User
                  </button>
                  {/* konten header akhir*/}
                </Tombol>
                <div className="col-lg-12">
                  <div className="card">
                    <div className="header"></div>
                    <div className="body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.map((u, index) => (
                              <tr
                                style={
                                  u.id % 2 === 0
                                    ? { backgroundColor: "#F9F2EC" }
                                    : { backgroundColor: "white" }
                                }
                                key={u.id}
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{u.name}</td>
                                <td>{u.email}</td>
                                <td>
                                  <div className="d-flex justfy-content-center">
                                    <span
                                      className="ti-pencil pr-2"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#editUser"
                                    ></span>
                                    <span
                                      className="ti-trash pl-2"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#delete"
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr className="footable-paging">
                              <td colSpan="4">
                                <ul className="pagination float-right">
                                  <span className="label label-default4">
                                    Page 1 of 2 &nbsp; {"<"} &nbsp; {">"}
                                  </span>
                                </ul>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
      {/* modal */}
      <FormTambahUser />
      <FormEditUser />
      <FormDelete />
    </>
  );
};

export default Users;
