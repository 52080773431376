import React from "react";
import { useForm } from "react-hook-form";

const FormTambahTeam = () => {
  const [addTeam, setAddTeam] = React.useState({});
  const { register, handleSubmit, errors } = useForm();
  const submit = (teamInput) => {
    setAddTeam(teamInput);
  };
  console.log(addTeam);
  return (
    <div
      className="modal fade "
      id="tambahTeam"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Tambah Team
            </h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="nama">Nama</label>
                <input
                  name="nama"
                  ref={register({ required: true })}
                  type="text"
                  id="nama"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.nama && (
                  <span className="text-danger text-muted mb-2">
                    nama harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="deskripsi">Deskripsi</label>
                <div className="form-line">
                  <textarea
                    rows="4"
                    name="deskripsi"
                    ref={register({ required: true })}
                    className="form-control no-resize"
                    style={{ backgroundColor: "#FFE8B1" }}
                  ></textarea>
                </div>
                {errors.deskripsi && (
                  <span className="text-danger text-muted mb-2">
                    Deskripsi harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="teamleader">Team Leader</label>
                <input
                  name="teamleader"
                  ref={register({ required: true })}
                  type="text"
                  id="teamleader"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.teamleader && (
                  <span className="text-danger text-muted mb-2">
                    Team leader harus diisi
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="member">Member</label>
                <input
                  name="member"
                  ref={register({ required: true })}
                  type="member"
                  id="member"
                  className="form-control"
                  style={{ backgroundColor: "#FFE8B1" }}
                />
                {errors.member && (
                  <span className="text-danger text-muted mb-2">
                    Member harus diisi
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
              >
                <b>Simpan</b>
              </button>
              <button
                type="button"
                style={{
                  marginRight: "30px",
                  color: "#000000",
                  background: "none",
                  float: "right",
                  border: "none",
                }}
                data-dismiss="modal"
              >
                Batalkan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTambahTeam;
