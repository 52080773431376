import React from "react";
import HeaderDashboard from "../../parts/HeaderDashboard";
import LeftBar from "../../parts/LeftBar";
import { Konten, Tombol } from "../../parts/HeaderDashboard/Style";
import CardTeam from "../../parts/CardTeam";
import { UserOne, UserTwo, UserThree, UserFour } from "../../parts/Assets";
import FormTambahTeam from "../../parts/FormTambahTeam";

const Teams = () => {
  const [squad, setSquad] = React.useState([]);
  const squadTeam = () => {
    setSquad([
      {
        id: 1,
        position: "Front-End Developer",
        role: "Team Lead",
        member: ["Cecep", "Solihin", "Yusup", "Johnes", "Edward"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipiscing elit Maecenas varius tortor nibh sit amet tempor nibh finibus et. Aenean eu enim justo vestibulum aliquam hendrerit moletie. Mauris malesuada nisi sit amet augue accusan tinicidunt",
        productivity: "75%",
        questEffectivity: "15%",
        effectivity: "5%",
        timeRfficiency: "80%",
        profile: UserOne,
      },
      {
        id: 2,
        position: "UI/ UX Developer",
        role: "Team Lead",
        member: ["rendy", "diero", "Marcello", "Johnes", "Edward"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipiscing elit Maecenas varius tortor nibh sit amet tempor nibh finibus et. Aenean eu enim justo vestibulum aliquam hendrerit moletie. Mauris malesuada nisi sit amet augue accusan tinicidunt",
        productivity: "75%",
        questEffectivity: "15%",
        effectivity: "5%",
        timeRfficiency: "80%",
        profile: UserTwo,
      },
      {
        id: 3,
        position: "Back-End Developer",
        role: "Team Lead",
        member: ["rendy", "diero", "Marcello", "Johnes", "Edward"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipiscing elit Maecenas varius tortor nibh sit amet tempor nibh finibus et. Aenean eu enim justo vestibulum aliquam hendrerit moletie. Mauris malesuada nisi sit amet augue accusan tinicidunt",
        productivity: "75%",
        questEffectivity: "15%",
        effectivity: "5%",
        timeRfficiency: "80%",
        profile: UserThree,
      },
      {
        id: 4,
        position: "Front-End Developer",
        role: "Team Lead",
        member: ["rendy", "diero", "Marcello", "Johnes", "Edward"],
        description:
          "Lorem ipsum dolor sit amet consectetur adipiscing elit Maecenas varius tortor nibh sit amet tempor nibh finibus et. Aenean eu enim justo vestibulum aliquam hendrerit moletie. Mauris malesuada nisi sit amet augue accusan tinicidunt",
        productivity: "75%",
        questEffectivity: "15%",
        effectivity: "5%",
        timeRfficiency: "80%",
        profile: UserFour,
      },
    ]);
  };

  React.useEffect(() => {
    squadTeam();
  }, []);
  if (window.location.href.substr(-2) !== "?r") {
    window.location = window.location.href + "?r";
  }
  return (
    <>
      <LeftBar menuActive="teams" />
      <HeaderDashboard />
      <Konten>
        <div className="container">
          <div className="body_scroll">
            <div className="block-body">
              {/* konten header */}
              <div className="row">
                <div className="col-lg-7 col-sm-5">
                  <p>
                    <b id="title">Team</b>
                    <br />
                    Team Mastery01
                  </p>
                </div>
                <Tombol className="col-lg-5">
                  <button
                    id="button"
                    className="btn mb-4 shadow float-right"
                    type="button"
                    data-toggle="modal"
                    data-target="#tambahTeam"
                  >
                    Tambah Team
                  </button>
                </Tombol>
                {/* konten header akhir*/}
                <div className="col-lg-12 rounded">
                  {squad.map((s) => (
                    <div className="container mt-4 mb-4" key={s.id}>
                      <CardTeam
                        prop={s.profile.url}
                        position={s.position}
                        role={s.role}
                        member={s.member}
                        description={s.description}
                        productivity={s.productivity}
                        questEffectivity={s.questEffectivity}
                        effectivity={s.effectivity}
                        timeRfficiency={s.timeRfficiency}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Konten>
      {/* modal */}
      <FormTambahTeam />
    </>
  );
};

export default Teams;
