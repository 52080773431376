import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../../parts/Header";
import Navbar from "../../parts/Navbar";
import ContentOne from "../../parts/ContentOne";
import ContentTwo from "../../parts/ContentTwo";
import Article from "../../parts/Article";
import { Head, Foot } from "./style";
import Footer from "../../parts/Footer";

const Home = () => {
  // aos-init aos-animate
  React.useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      duration: 1500,
      useClassNames: true,
      animatedClassName: "aos-animate",
      debounceDelay: 50,
      throttleDelay: 99,
      delay: 300,
      anchorPlacement: "top-bottom",
      once: false,
    });
    AOS.refresh();
  }, []);

  return (
    <section>
      <Navbar />
      {/* header */}
      <Head className="container-fluid">
        <Header />
      </Head>
      {/* koten */}
      <ContentOne />
      {/*}
      <ContentTwo />{*/}
      {/* footer */}
      {/* <Article />*/}
      <Foot className="container-fluid">
        <Footer />
      </Foot>
    </section>
  );
};

export default Home;
