import React from "react";

const FormDelete = () => {
  return (
    <div
      className="modal fade"
      id="delete"
      tabIndex="-1"
      role="dialog"
      style={{ fontFamily: "Poppins Regular" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="title" id="defaultModalLabel">
              Hapus Data?
            </h5>
          </div>
          <div className="modal-body">
            <p>Data akan dihapus secara permanen, apakah anda yakin?</p>
            <button
              type="submit"
              style={{
                color: "#000000",
                background: "none",
                float: "right",
                border: "none",
              }}
            >
              <b>Hapus</b>
            </button>
            <button
              type="button"
              style={{
                marginRight: "30px",
                color: "#000000",
                background: "none",
                float: "right",
                border: "none",
              }}
              data-dismiss="modal"
            >
              Batalkan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDelete;
